@import '../../settings/colors';
@import '../../settings/typos';
@import '../../settings/media-queries';

$radioSize: 1.4rem;

.questionInput {
    input[type=radio] {
        width: $radioSize;
        height: $radioSize;
        accent-color: $thunder;
    }
}

.multipleChoice {
    @extend .questionInput;

    display: grid;
    grid-template-columns: $radioSize auto;
    align-items: center;
    gap: 15px 10px;

    @include mq480 {
        grid-template-columns: auto;
        row-gap: 0;

        input[type=radio] {
            position: absolute;
            visibility: hidden;
            opacity: 0;
        }

        input[type=radio]+label {
            padding: 10px;
            border-radius: 0.25rem;
        }
        
        input[type=radio]:checked+label {
            background-color: $turquoise;
            color: $white;
        }
    }
}