@import '../../settings/colors';
@import '../../settings/typos';
@import '../../settings/media-queries';

.container {
    background: $white;
    padding: 3.875rem 4.438rem;
    min-height: 27.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include mq900 {
        padding: 1.875rem 2.438rem;
    }

    @include mq480 {
      padding: 2rem 1rem;
    }

    .actionsContainer {
        margin-top: 1rem;
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        gap: 1rem;
    
        & > * {
            @include mq480 {
                width: 100%;
            }
        }
    }

    b {
        font-weight: bold;
    }
}

.spinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3rem 0;
}

.subTitle {
    color: $primary;
    font-size: $fontSizeXLarge;
    font-weight: bold;
    font-family: Merriweather, sans-serif;
    padding-bottom: 0;
    margin-bottom: 2rem;

    &:has(.icon) {
        display: flex;
        gap: 1rem;
        align-items: center;
    }

    .icon {
        display: flex;
        width: 3rem;
    }
}

.questionContainer {
    margin-bottom: 1.75rem;

    .questionTitle {
        color: $thunder;
        letter-spacing: .1px;
        margin-bottom: 20px;
        font-size: $fontSizeSemiLarge;
        display: flex;
        gap: 10px;

        span {
            font-weight: bold;
        }

        @include mq480 {
            flex-direction: column;
        }
    }

    .questionText > span {
        margin-left: 5px
    }
}

.markdown * {
    all: revert;
}