@import '../../settings/colors';
@import '../../settings/typos';
@import '../../settings/media-queries.scss';

.tabsList {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid $black\.6;
  justify-content: space-evenly;
  padding-bottom: 2rem;
  padding-top: 2rem;
  background-color: $white;
}

.tabsListLeftAligned {
  justify-content: normal;

  .tabButton {
    margin: 0 0.938rem;
  }
}

.tabsNoBorder {
  border-bottom: none;
}

.tabButton {
  font-family: Roboto, sans-serif;
  font-weight: 700;
  color: $primaryAlt;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  outline: none;
  background-color: $white;
  transition: background-color 0.2s ease-in-out 0s;
  border-radius: 1.5rem;

  &:hover {
    background-color: $dustyGray\.5;
  }
}

.activeButton {
  background-color: $dustyGray\.5;
}

.ufcQuizList {
  border-top: 1px solid $black\.6;
  padding-bottom: 2rem;
  padding-top: 2rem;

  h2 {
    font-family: Roboto, sans-serif;
    font-weight: 700;
    color: $primaryAlt;
    border: none;
    padding: 0 1rem;
  }

  .item {
    display: flex;
    align-items: center;
    padding: 1.25rem 0;
    width: 100%;
  
    @include mq600 {
      justify-content: space-around;
    }
  }
  
  .name {
    margin-left: 0.5rem;
    font-weight: 500;
    font-size: $fontSizeNormal;
    color: $primary;
  
    @include mq1200 {
      max-width: 50%;
    }
  
    @include mq600 {
      max-width: 30%;
    }
  }
}
